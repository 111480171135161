// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../node_modules/postcss-loader/src/index.js??postcss!@blueprintjs/core/lib/css/blueprint.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../node_modules/postcss-loader/src/index.js??postcss!@blueprintjs/icons/lib/css/blueprint-icons.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "html, body {\n  margin: 0;\n  padding: 0;\n  font-size: 1em;\n  line-height: 1.61em;\n  height: 100%;\n  font-family: \"Noto Sans\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nspan, h1, h2, h3, h4, h5{\n  font-family: \"Montserrat\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\nblockquote {\n\tfont-size: 1.4em;\n\twidth: 80%;\n\tmargin: 50px auto;\n\tfont-family: Open Sans;\n\tfont-style: italic;\n\tcolor: #555555;\n\tpadding: 1.2em 30px 1.2em 75px;\n\tborder-left: 8px solid #9d7ff2;\n\tline-height: 1.6;\n\tposition: relative;\n\tbackground: #ededed;\n}\n\na {\n\tcolor: #5219ee;\n}\n\nblockquote::before {\n\tfont-family: Arial;\n\tcontent: '\\201C';\n\tcolor: #9d7ff2;\n\tfont-size: 4em;\n\tposition: absolute;\n\tleft: 10px;\n\ttop: -10px;\n}\n\nblockquote::after {\n\tcontent: '';\n}\n\nblockquote span {\n\tdisplay: block;\n\tcolor: #333333;\n\tfont-style: normal;\n\tfont-weight: bold;\n\tmargin-top: 1em;\n}\n\n.format-toolbar {\n\tdisplay: flex;\n\tborder-bottom: solid 1.7px rgba(199, 198, 255, 0.15);\n\tpadding: 10px 0;\n\tmargin: 0 0 20px 0;\n}\n\n.tooltip-icon-button {\n\tcursor: pointer;\n\tborder: 0;\n}\n\n.format-cms-sidebar {\n\tbackground: #ffffff\n}\n\n.form-group {\n\tmargin-top: 1em;\n}\n\n.form-group input, .form-group textarea, .md-form input, .margin-fix input {\n\tbackground-color: #ededed;\n\twidth: 100%;\n}\n\n.form-button-margin {\n\tmargin-left: -9px;\n}\n\n", ""]);
// Exports
module.exports = exports;
