
export const UserType = {
    REQUEST_USER_LOGOUT: "REQUEST_USER_LOGOUT",
    REQUEST_USER_LOGIN: "REQUEST_USER_LOGIN",
    REQUEST_USER_REGISTER: 'REQUEST_USER_REGISTER',

    REQUEST_USER_ACCOUNT_RECOVERY: 'USER_ACCOUNT_RECOVERY',
    REQUEST_USER_ACCOUNT_RESETTING: 'REQUEST_USER_ACCOUNT_RESETTING',
    REQUEST_USER_CONTACT_US: 'REQUEST_USER_CONTACT_US',
    REQUEST_USER_CONTACT: 'REQUEST_USER_CONTACT',
    REQUEST_USER_ALL: 'REQUEST_USER_ALL',
    REQUEST_USER_SINGLE: 'REQUEST_USER_SINGLE',
    REQUEST_USER_EDIT: 'REQUEST_USER_EDIT',
    REQUEST_USER_DELETE: 'REQUEST_USER_DELETE',
}
