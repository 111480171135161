
export const GeneralType = {
    
    REQUEST_HAS_ERRORED: "REQUEST_HAS_ERRORED",
    REQUEST_IS_LOADING: "REQUEST_IS_LOADING",
    REQUEST_IS_UPLOADING: "REQUEST_IS_UPLOADING",
    UNKNOWN_ACTION: "UNKNOWN_ACTION",
    REQUEST_FETCH_SUCCESS: "REQUEST_FETCH_SUCCESS",
    REQUEST_UPDATE_SUCCESS: "REQUEST_UPDATE_SUCCESS",
    REQUEST_DELETE_SUCCESS: "REQUEST_DELETE_SUCCESS",
    REQUEST_CREATE_SUCCESS: "REQUEST_CREATE_SUCCESS",
    REQUEST_CLEAR_PROPS: "REQUEST_CLEAR_PROPS",

}
