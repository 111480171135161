
export const UserEventType = {

    EVENT_USER_SAVE: 'EVENT_USER_SAVE',
    EVENT_USER_EDIT: 'EVENT_USER_EDIT',
    EVENT_USER_ARCHIVE: 'EVENT_USER_ARCHIVE',
    EVENT_USER_PUBLISH: 'EVENT_USER_PUBLISH',
    EVENT_USER_UNPUBLISH: 'EVENT_USER_UNPUBLISH',
    EVENT_USER_DELETE: 'EVENT_USER_DELETE',
    EVENT_USER_DEFAULT: 'EVENT_USER_DEFAULT',
    EVENT_USER_CREATE: 'EVENT_USER_CREATE',
    EVENT_USER_LIST: 'EVENT_USER_LIST',
    EVENT_USER_RELOAD: 'EVENT_USER_RELOAD',

}