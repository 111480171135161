// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/malawi.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Nunito:400,700);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#notfound {\n    position: relative;\n    height: 100vh;\n}\n\n#notfound .notfound {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n}\n\n.notfound {\n    max-width: 560px;\n    width: 100%;\n    padding-left: 160px;\n    line-height: 1.1;\n}\n\n.notfound .notfound-404 {\n    position: absolute;\n    left: 0;\n    top: 0;\n    display: inline-block;\n    width: 140px;\n    height: 140px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: cover;\n}\n\n.notfound .notfound-404:before {\n    content: '';\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    transform: scale(2.4);\n    border-radius: 50%;\n    background-color: #f2f5f8;\n    z-index: -1;\n}\n\n.notfound h1 {\n    font-family: 'Nunito', sans-serif;\n    font-size: 60px;\n    font-weight: 500;\n    margin-top: 0px;\n    margin-bottom: 10px;\n    color: #151723;\n    text-transform: uppercase;\n}\n\n.notfound h2 {\n    font-family: 'Nunito', sans-serif;\n    font-size: 21px;\n    font-weight: 400;\n    margin: 0;\n    text-transform: uppercase;\n    color: #151723;\n}\n\n.notfound p {\n    font-family: 'Nunito', sans-serif;\n    color: #999fa5;\n    font-weight: 400;\n}\n\n.notfound a {\n    font-family: 'Nunito', sans-serif;\n    display: inline-block;\n    font-weight: 700;\n    border-radius: 40px;\n    text-decoration: none;\n    color: #388dbc;\n}\n\n@media only screen and (max-width: 767px) {\n    .notfound .notfound-404 {\n        width: 110px;\n        height: 110px;\n    }\n    .notfound {\n        padding-left: 15px;\n        padding-right: 15px;\n        padding-top: 110px;\n    }\n}", ""]);
// Exports
module.exports = exports;
